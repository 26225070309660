/**
 * Styles specific to page hero sections
 * @author      Jeff Adams <jeff@tinyfly.com>
 * @copyright   Traxo, Inc. 2021
 */

@layer components {
  .platform-hero-image {
    background-image: url("../images/backgrounds/bg-platform-hero.png");
    background-position: calc(50% + 360px) calc(100% - 5.5vw);
    background-size: 580px 612px;
    background-repeat: no-repeat;
  }

  .affiliate-hero-image {
    background-image: linear-gradient(145deg, rgba(69, 67, 164, .7) 0%, rgba(1, 103, 158, 1) 120%),
                      linear-gradient(145deg, rgba(68, 166, 219, .4) 0%, rgba(19, 85, 120, 1) 120%),
                      url("../images/backgrounds/bg-affiliate-hero.jpg");
    background-blend-mode: normal, lighten, normal;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
