/**
 * Custom tooltip styles for tippy.js library
 * @author      Jeff Adams <jeff@tinyfly.com>
 * @copyright   Traxo, Inc. 2021
 */

.tippy-box[data-theme~='txo-blue'] {
  background-color: theme('colors.brand.blue.DEFAULT');
  color: theme('colors.white');
}

.tippy-box[data-theme~='txo-blue'] a {
  color: theme('colors.white');
  font-weight: theme('fontWeight.normal');
  text-decoration: underline;
}

.tippy-box[data-theme~='txo-blue'] a:hover,
.tippy-box[data-theme~='txo-blue'] a:focus {
  color: theme('colors.white');
  text-decoration: none;
}

.tippy-box[data-theme~='txo-blue'][data-placement^='top'] > .tippy-arrow::before {
  border-top-color: theme('colors.brand.blue.DEFAULT');
}

.tippy-box[data-theme~='txo-blue'][data-placement^='bottom'] > .tippy-arrow::before {
  border-bottom-color: theme('colors.brand.blue.DEFAULT');
}

.tippy-box[data-theme~='txo-blue'][data-placement^='left'] > .tippy-arrow::before {
  border-left-color: theme('colors.brand.blue.DEFAULT');
}

.tippy-box[data-theme~='txo-blue'][data-placement^='right'] > .tippy-arrow::before {
  border-right-color: theme('colors.brand.blue.DEFAULT');
}
