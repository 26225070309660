/**
 * Apply color styling to the header in various situations
 * all other styles are inline with tailwind classes
 * @author      Jeff Adams <jeff@tinyfly.com>
 * @copyright   Traxo, Inc. 2021
 */

@layer components {
  /* Default colors on a light hero background
  -------------------------------------------------- */
  .header {
    @apply bg-transparent;
  }

  .header__logo {
    @apply text-brand-black hover:text-brand-black;
  }

  .header__menu {
    @apply text-brand-black hover:text-brand-purple focus:text-brand-purple;
  }

  .header:not(.js-header--scrolling) .header__nav {
    @apply bg-white lg:bg-transparent;
  }

  .nav-link {
    @apply text-brand-black hover:text-brand-purple focus:text-brand-purple;
  }

  .nav-link--active {
    @apply text-brand-purple;
  }

  /* When on a dark hero background
     (the scrolling header gets the default light background)
  -------------------------------------------------- */
  .header--dark {}

  .header--dark:not(.js-header--scrolling) .header__logo {
    @apply text-white hover:text-white;
  }

  .header--dark:not(.js-header--scrolling) .header__menu {
    @apply text-white hover:text-brand-purple focus:text-brand-purple;
  }

  /* open menu when not scrolling */
  .header--dark:not(.js-header--scrolling) .header__nav {
    @apply bg-brand-blue-dark lg:bg-transparent;
  }

  .header--dark:not(.js-header--scrolling) .nav-link {
    @apply text-white hover:text-brand-purple focus:text-brand-purple;
  }

  .header--dark:not(.js-header--scrolling) .nav-link--dropdown {
    @apply lg:text-brand-black lg:hover:text-brand-purple lg:focus:text-brand-purple;
  }

  .header--dark:not(.js-header--scrolling) .nav-link--active {
    @apply text-brand-purple;
  }

  /* When scrolling and it is fixed to the top of the screen
  -------------------------------------------------- */
  .js-header--scrolling {
    @apply bg-white fixed shadow;
  }
}
