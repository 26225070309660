/*
Filename:  marketing.css
Description: Styles for the public marketing website

  We use the tailwind css framework. It consists of 3
  types of styles.
  1) Base (root "naked" elements),
  2) Components (Basically styles for a thing, e.g. buttons,
      tables, forms, etc...), and
  3) Utilites (Individual css properies, e.g. border-radius,
      display, font-size, etc...).
  Tailwind requires that you put custom styles after tailwind's
  imports according to what type of styles you are creating.
  These are all processed via PostCSS during the build process.
  and unused styles are removed via PurgeCSS for production builds.
  @see: https://tailwindcss.com/

  We also have access to Tailwind UI, with prebuilt examples as a
  good starting point for new components (Login info in the company
  password manager)
  @see: https://tailwindui.com/
*/

/* 1) Base Styles
-------------------------------------------------- */
@import 'tailwindcss/base';
@import './_fonts.css';
@import './_base.css';

/* 2) Component Styles
-------------------------------------------------- */
@import 'tailwindcss/components';
@import 'tippy.js/dist/tippy.css';
@import './_header.css';
@import './_tooltip.css';
@import './_slider.css';
@import './_range.css';
@import './_hero.css';

/* 3) Utility Styles
-------------------------------------------------- */
@import 'tailwindcss/utilities';
@import './_clip-path.css';
