/*
  Filename:  range.css
  Description: Custom styling for range inputs. This relies
               on inline css variables of --min, --max, --val
               data-controller="range" will wire up the JS to take care of it
  @see: https://css-tricks.com/sliding-nightmare-understanding-range-input/
*/
[type=range] {
  --range: calc(var(--max) - var(--min));
  --ratio: calc((var(--val) - var(--min))/var(--range));
  --sx: calc(.5*2rem + var(--ratio)*(100% - 2rem));
  margin: 0;
  padding: 0;
  width: 100%;
  height: 2rem;
  background: transparent;
  font: 1em/1 arial, sans-serif;
}

[type=range], [type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
}

[type=range]::-webkit-slider-runnable-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.5rem;
  background: #edeef1;
  border-radius: 0.25rem;
}

[type=range].js::-webkit-slider-runnable-track {
  background: linear-gradient(theme('colors.brand.blue.DEFAULT'), theme('colors.brand.blue.DEFAULT')) 0/var(--sx) 100% no-repeat #edeef1;
}

[type=range]::-moz-range-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.5rem;
  background: #edeef1;
  border-radius: 0.25rem;
}

[type=range]::-ms-track {
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 0.5rem;
  background: #edeef1;
  border-radius: 0.25rem;
}

[type=range]::-moz-range-progress {
  height: 0.5rem;
  background: theme('colors.brand.blue.DEFAULT');
  border-radius: 0.25rem;
}

[type=range]::-ms-fill-lower {
  height: 0.5rem;
  background: theme('colors.brand.blue.DEFAULT');
  border-radius: 0.25rem;
}

[type=range]::-webkit-slider-thumb {
  margin-top: -0.75rem;
  box-sizing: border-box;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  cursor: grab;
}

[type=range]::-webkit-slider-thumb:hover {
  background: #ebf4f9;
}

[type=range]::-moz-range-thumb {
  box-sizing: border-box;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  cursor: grab;
}

[type=range]::-moz-range-thumb:hover {
  background: #ebf4f9;
}

[type=range]::-ms-thumb {
  margin-top: 0;
  box-sizing: border-box;
  border: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  cursor: grab;
}

[type=range]::-ms-thumb:hover {
  background: #ebf4f9;
}

[type=range]::-ms-tooltip {
  display: none;
}
