/**
 * Custom image slider styles for keen.js library
 * @see https://keen-slider.io/
 */
@layer components {
  .slider:not([data-keen-slider-disabled]) {
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    width: 100%;
  }
  .slider:not([data-keen-slider-disabled]) .slider__slide {
    position: relative;
    overflow: hidden;
    flex: none;
    min-height: 100%;
  }
  .slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
    flex-direction: row-reverse;
  }
  .slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
    flex-wrap: wrap;
  }
}
